// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require.context('../images', true)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;
// Load Datatables
require('datatables.net')(window, $)
require('datatables.net-select')(window, $)
require('datatables.net-bs4')(window, $)
require('datatables.net-buttons-bs4')(window, $)
// require('datatables.net-responsive-bs')(window, $)
require('webpack-jquery-ui/autocomplete');
require('webpack-jquery-ui/datepicker');

import 'bootstrap'
import "./custom";
import 'jquery-ui/ui/core.js';
// import 'bootstrap/dist/css/bootstrap.css';
// import "bootstrap/js/dist/tooltip";

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
})
import '../stylesheets/application';
