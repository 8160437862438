var icon_options = {'arrow_show': 'arrow', 'arrow_hide': 'arrow', 'check_show': 'check', 'check_hide': 'check', 'plus_show': 'plus', 'plus_hide': 'plus'}
$(function() {
  // Toggle Tooltip
  $('[data-toggle="tooltip"]').tooltip();
  // Toggle Popover
  $('[data-toggle="popover"]').popover();

});

$(document).on('click touch', '.save_invoice_save', function() {
  var stop_process = false;
  var mandatory = [];
  $(".mandatory").hide(); $(".invoice_issues").html(""); $(".invoice_alert_issues").hide();
  if($("#invoice_invoice_type").val() == ""){ stop_process = true; mandatory.push('invoice_type'); }
  if($("#invoice_date_invoice").val() == ""){ stop_process = true; mandatory.push('date_invoice'); }
  if($("#invoice_customer_id").val() == ""){ stop_process = true; mandatory.push('customer_id'); }
  if(stop_process){
    mandatory.forEach(function(item){ $("#invoice_"+item).parent().find(".mandatory").show(); });
    if(mandatory.length > 0){ $(".invoice_alert_issues").show(); $(".invoice_issues").html("There are fields missing. Check all fields."); }
    setTimeout(function(){
      $(".save_invoice_save").removeClass('disabled');
      $(".save_invoice_save").html("<i class='fas fa-check-circle'></i> Save");
    },500);
  }else{
    $("#form_invoice_form").submit();
  }
});

$(document).on('click touch', '.closeAlert', function() { $($(this).data("target")).hide(); });

$(document).on('click touch', '.dyn-collapse-trigger', function() {
  var target = $(this).data("target");
  if($(target).hasClass('show')){ $(target).removeClass('show'); }else{ $(target).addClass('show') }
});

$(document).on('click touch', '.btn-toggler', function() {
  var icon_item = $(this).find("i");
  var type = icon_options[icon_item.attr('class')];
  if(icon_item.hasClass(type+'_show')){
    $(this).parent().parent().find('.btnADD').hide();
    icon_item.removeClass(type+'_show');
    icon_item.addClass(type+'_hide');
  }else{
    $(this).parent().parent().find('.btnADD').show();
    icon_item.removeClass(type+'_hide');
    icon_item.addClass(type+'_show');
  }
});

$(document).on('click touch', '.saveEntity', function() { var target = $(this).data("entity"); $(".save_"+target+"_save").click(); });

$(document).on('click touch', '.deleteEntity', function() {
  var target = $(this).data("entity");
  var singular = $(this).data("singular");
  var id = $(this).data("id");
  var path = $(this).data("path");
  if(confirm("Are you sure you want to delete this '"+singular+"'")){
    $(".deleteElement").removeClass("deleteEntity");
    $(".deleteElement").html("<i class='fas fa-spin fa-spinner'></i> Deleting</span>");
    $.ajax({
      cache: false, url: '/'+path+target +'/'+id, method: 'DELETE',
      success: function(data) { location.href='/'+target; },
      error: function(data) { alert("Error while deleting."); console.log(data); $(".deleteElement").html("<i class='fas fa-trash'></i> Delete</span>"); $(".deleteElement").addClass("deleteEntity"); },
    });
  }
});
$(document).on('click touch', '.reactivateEntity', function() {
  $(".deleteElement").html("<i class='fas fa-spin fa-spinner'></i> Reactivating</span>");
  var target = $(this).data("entity");
  var id = $(this).data("id");
  var path = $(this).data("path");
  location.href='/'+path+target+'/'+id+'/reactivate';
});
$(document).on('click touch', '.save_loading', function() {
  $(".save_loading").html("<i class='fas fa-spin fa-spinner'></i> Saving");
  $(this).addClass('disabled');
});
$(document).on('click touch', '.save_loading_async', function() {
  $(".save_loading_async").html("<i class='fas fa-spin fa-spinner'></i> Saving");
  $(this).addClass('disabled');
});
$(document).on('click touch', '.deletePayment', function() {
  var id = $(this).data('id');
  if(confirm('Are you sure you want to delete this Payment?')){
    $(".delPay"+id).html("<i class='fas fa-spin fa-spinner'></i>");
    $.ajax({
      type: 'DELETE', cache: false, url: '/payments/'+id, data: '',
      success: function(){ window.location.reload(true); },
      error: function(jqXHR, textStatus, errorThrown){ $(".delPay"+id).html("<i class='far fa-trash-alt'></i>"); }
    });
  }
});

$(document).on('change', '.switch_newdit', function() {
  var value = $(this).val();
  var element = $(this).data('element');
  if(value != ''){
    $(".newdit_"+element).data("action","edit");
    $(".newdit_"+element).html("<i class='fas fa-pencil-alt text-primary'></i>");
  }else{
    $(".newdit_"+element).data("action","new");
    $(".newdit_"+element).html("<i class='fas fa-plus-circle text-success'></i>");
  }
});

$(document).on('click touch', '.newdit', function() {
  var action = $(this).data("action");
  var element = $(this).data("element");
  console.log("Action: "+action+" | Element: "+element);
  $(".the_modal_title").html(action+" "+$(this).data('title'));
  if(element == 'equipment'){
    var contact_id = $("#invoice_customer_id").val();
    console.log("Element: 'Equipment' | ContactID: "+contact_id);
    if(contact_id == ''){
      $('.the_modal_body').html("<p>You must choose, or create, a contact to create a new equipment.</p>");
    }else{
      if(action == 'new'){
        $.get("/contacts/"+contact_id+"/equipment/new?input_type=modal", '', function(data){
          $('.the_modal_body').html(data);
        }, "html");
      }else{
        var equipment_id = $("#invoice_zimple_equipment_id").val();
        $.get("/contacts/"+contact_id+"/equipment/"+equipment_id+"/edit?input_type=modal", '', function(data){
          $('.the_modal_body').html(data);
        }, "html");
      }
    }
  }else if (element == 'contacts') {
    var contact_id = $("#invoice_customer_id").val();
    console.log("Element: 'Contact' | ContactID: "+contact_id);
    if(action == 'new'){
      var addition = $(this).data("addition");
      $.get("/contacts/new?input_type=modal"+addition, '', function(data){
        $('.the_modal_body').html(data);
      }, "html");
    }else{
      if(contact_id != ''){
        $.get("/contacts/"+contact_id+"/edit?input_type=modal", '', function(data){
          $('.the_modal_body').html(data);
        }, "html");
      }
    }
  }else if (element == 'items') {
    var element_id = $(this).data('elementid');
    var item_id = $(".item_id_"+element_id).val();
    var addition = $(this).data("addition");
    console.log("Element: 'Items' | ElementID: "+element_id+ " | ItemID: "+item_id);
    if(action == 'new'){
      $.get("/items/new?input_type=modal"+addition, '', function(data){
        $('.the_modal_body').html(data);
      }, "html");
    }else{
      if(contact_id != ''){
        $.get("/items/"+item_id+"/edit?input_type=modal"+addition, '', function(data){
          $('.the_modal_body').html(data);
        }, "html");
      }
    }
  }
});


$(document).on('click touch', '.emailSale', function() {
  var target = $(this).data("entity");
  var id = $(this).data("id");
  $(".emailSale").html("<i class='fas fa-spin fa-spinner'></i> Sending</span>");
  $.ajax({
    type: "POST", cache: false, url: "/sales/"+id+"/email_invoice",
    success: function(response){
      if(response["result"] == "success"){
        $(".flash_home").html("<div class='temp_alert alert-success'><i class='fas fa-paper-plane'></i> "+response["message"]+"</div>");
        $(".mobileWarning").html("<div class='temp_alert alert-success'><i class='fas fa-paper-plane'></i> "+response["message"]+"</div>");
      }else{
        $(".flash_home").html("<div class='temp_alert alert-danger'><i class='fas fa-exclamation-triangle'></i> "+response["message"]+"</div>");
        $(".mobileWarning").html("<div class='temp_alert alert-danger'><i class='fas fa-exclamation-triangle'></i> "+response["message"]+"</div>");
      }
      $(".emailSale").html("<i class='fas fa-envelope text-info'></i> Email</span>");
      setTimeout(function(){ $(".temp_alert").fadeOut('slow'); setTimeout(function(){ $(".temp_alert").remove(); },400); }, 5000);
    }
  });
});

$(document).on('click touch', '.tickItem', function(){
  var type = $(this).data('type');
  console.log("SellBuy: "+type);
  if($(this).hasClass("fa-check-square")){
    console.log("AlreadyChecked -> disable fields and untick box");
    $(this).removeClass("fa-check-square");
    $(this).addClass("fa-square");
    $(".showTick_"+type).removeClass("fa-check-square");
    $(".showTick_"+type).addClass("fa-square");
    $("#item_is_"+type).val('false');
    $(".Element_"+type).attr("readonly",true);
  }else{
    console.log("Allow EDIT");
    $(this).addClass("fa-check-square");
    $(this).removeClass("fa-square");
    $(".showTick_"+type).addClass("fa-check-square");
    $(".showTick_"+type).removeClass("fa-square");
    $("#item_is_"+type).val('true');
    $(".Element_"+type).attr("readonly",false);
  }
});

// BULK
$(document).on('click touch', '.cancelBulk', function(){ $('#form_'+$(this).data("entity")+'_form').trigger("reset"); });
$(document).on('click touch', '.saveBulk', function(){
  // console.log("Triggered SaveBulk");
  $(this).html("<i class='fas fa-spinner fa-spin text-success'></i> Saving");
  if($("#fields_amt").val() > parseInt($("#fields_limit").val())){
    alert("You have too many fields to update at once.\n\nTry filtering the list to reduce the amount of records to "+$("#fields_limit").val());
  }else{
    var at_least_one_edit = false;
    if($(this).data("entity") == 'tasks'){
      for (var i = 0; i < $("#fields_amt").val(); i++) {
        var cancel_line = true;
        if(parseInt($("#assigned_"+i).val()) != parseInt($("#assigned_"+i).data("original"))){ cancel_line = false; console.log("Different..: USER | Val..: '"+$("#assigned_"+i).val()+"' | Original: "+ $("#assigned_"+i).data("original")); }
        if($("#reason_"+i).val() !== undefined && parseInt($("#reason_"+i).val()) != parseInt($("#reason_"+i).data("original"))){ cancel_line = false; console.log("Different..: REASON | Val..: '"+$("#reason_"+i).val()+"' | Original: "+ $("#reason_"+i).data("original") + " || Condition: '"+($("#reason_"+i).val() !== 'undefined')+"'"); }
        if($("#notes_"+i).val() != $("#notes_"+i).data("original")){ cancel_line = false; console.log("Different..: NOTES | Val..: '"+$("#notes_"+i).val()+"' | Original: "+ $("#notes_"+i).data("original")); }
        if(cancel_line == true){
          $("#id_"+i).prop("disabled", "disabled");
          $("#assigned_"+i).prop("disabled", "disabled");
          $("#reason_"+i).prop("disabled", "disabled");
          $("#notes_"+i).prop("disabled", "disabled");
        }else{
          at_least_one_edit = true;
        }
      }
      if(at_least_one_edit){
        $('#form_'+$(this).data("entity")+'_form').trigger("submit");
      }else{
        for (var i = 0; i < $("#fields_amt").val(); i++) {
          $("#id_"+i).prop("disabled", false);
          $("#assigned_"+i).prop("disabled", false);
          $("#reason_"+i).prop("disabled", false);
          $("#notes_"+i).prop("disabled", false);
        }
        setTimeout(function(){ $(".saveBulk").html("<i class='fas fa-save text-success'></i> Save Changes"); },250);
      }
    }else{
      $('#form_'+$(this).data("entity")+'_form').trigger("submit");
    }
  }
});

$(document).on('click touch', '.save_element_async', function() {
  // console.log("Saving Loading Async");
  var the_id = $(this).data("id");
  var the_element = $(this).data("element");
  var the_form = 'form_'+$(this).data("form")+'_form';
  var pre_path = $(this).data("path");
  // console.log("Form: "+the_form+" | Path: "+pre_path);
  $.ajax({
    type: 'POST', cache: false, data: $("#"+the_form).serialize(), url: '/'+pre_path+the_element+'/'+the_id,
    success: function(response){
      // console.log("TheResponse..: "+response);
      if(the_element == 'equipment'){
        if(the_id == ""){
          $("#invoice_zimple_equipment_id").append("<option value='"+response['id']+"'>"+response['name']+"</option>");
          $("#invoice_zimple_equipment_id").val(response['id']).change();
        }
      }else if(the_element == 'contacts'){
        if(the_id == ""){
          $("#customer_name").val(response['name']);
          $("#invoice_customer_id").val(response['id']).change();
        }
      }else if(the_element == 'items'){
        var unit_price = $("#invoice_transaction_type").val() == 'S' ? response['item']['sell_price'] : response['item']['buy_price'];
        var gst_amount = parseFloat(unit_price) * 0.1;
        var lineNum = response['lineNum'];
        // console.log('LineNum......: '+response['lineNum']);
        // console.log('TheItem......: '+response['item']);
        // console.log('Description..: '+response['item']['description']);
        // console.log('UnitPrice....: '+unit_price);
        // console.log('GSTAmount....: '+gst_amount);
        // console.log("#invoice_line_items_attributes_"+lineNum+"_description should receive..: "+response['item']['description']);
        // console.log("#invoice_line_items_attributes_"+lineNum+"_unit_price should receive..: "+unit_price);
        $("#invoice_line_items_attributes_"+lineNum+"_item_id").val(response['item']['id']);
        $("#item_code_"+lineNum).val(response['item']['item_number']);
        $("#invoice_line_items_attributes_"+lineNum+"_description").val(response['item']['description']);
        $("#invoice_line_items_attributes_"+lineNum+"_tax_gcode").data("rate",'0.1');
        $("#invoice_line_items_attributes_"+lineNum+"_unit_price").val(parseFloat(unit_price));
        // console.log("#invoice_line_items_attributes_"+lineNum+"_gst_amount should receive..: "+gst_amount);
        $("#invoice_line_items_attributes_"+lineNum+"_gst_amount").val(parseFloat(gst_amount));
        // console.log("#invoice_line_items_attributes_"+lineNum+"_gst_amount RECEIVED..: "+ $("#invoice_line_items_attributes_"+lineNum+"_gst_amount").val());
        var line_to_refresh = $("#invoice_line_items_attributes_"+lineNum+"_id").parent().data('row');
        // console.log("Re-calculating..: 'LineNum_"+line_to_refresh+"' collecting from #invoice_line_items_attributes_"+lineNum+"_id");
        updateSubTotalLine(line_to_refresh);
      }
      $(".the_modal").modal("hide");
    }
  });
  // console.log("Form "+the_form+" Sent!!!");
});


$(document).ready(function(){

  // Clear Alert Message
  setTimeout(function(){ $(".temp_alert").fadeOut('slow'); setTimeout(function(){ $(".temp_alert").remove(); },400); }, 5000);

  // SCROLL
  $(window).on("scroll", function() { if($(window).scrollTop() <= 0){ $(".searchLine").slideDown(); } });

});


// FUNCTIONS

function setIssues(target,issue){
  $(target).parents(".form-group").addClass("has-error");
  $(target).parents(".form-group").addClass("redColor");
  $(target).parents(".form-group").addClass(issue);
  $(target).addClass("shineAlert");
  $(target).focus();
  setTimeout(function(){ $(target).removeClass("shineAlert"); }, 1000);
}

// function updateInvoiceTotal(){
//   // Update Total Value of the Invoice
//   var amt = $("#items_amount").val();
//   var received = $("#total_received_field").val();
//   var total = 0;
//   var total_gst = 0;
//   for(var i = 0; i<amt; i++){
//     if($(".destroy_"+i).val() != 'true'){
//       total += parseFloat($("#item_total_price_"+i).val());
//       total_gst += parseFloat($(".gst_amount_"+i).val());
//     }
//   }
//   $("#total_gst").val(total_gst.toFixed(2));
//   $("#total").val(((total+total_gst)-received).toFixed(2));
// }
//
// function updateSubTotalLine(lineNum){
//   var qty = parseFloat($(".qty_"+lineNum).val());
//   var u_price = parseFloat($(".price_"+lineNum).val());
//   var rate = parseFloat($(".tax_gcode_"+lineNum).data("rate"));
//   var subtotal_gst = u_price * rate * qty;
//   // console.log("U_Price: '"+u_price+"' * Rate: '"+rate+"' * Qty: '"+qty+"' = SubTotalGST:" + subtotal_gst);
//   var total = qty*u_price;
//   $(".gst_amount_"+lineNum).val( parseFloat(subtotal_gst).toFixed(2) );
//   $(".total_line_"+lineNum).val(total.toFixed(2));
//   updateInvoiceTotal();
// }
